<template>
  <v-container fluid>
    <v-row class="mb-1">
      <!-- Loading -->
      <v-col v-if="allLoading">
        <div class="d-flex align-center justify-center qj-loading">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="70"
          ></v-progress-circular>
        </div>
      </v-col>
      <!-- Com arquivos -->
      <template v-for="asset in assetsPlusDate" v-else>
        <v-col
          :key="asset.id"
          cols="12"
          :class="
            asset.isDate ? 'pa-0' : 'col-xs-12 col-sm-4 col-md-3 col-lg-2'
          "
        >
          <v-subheader v-if="asset.isDate">{{
            asset.date | formatDate
          }}</v-subheader>
          <v-hover v-else>
            <template v-slot:default="{ hover }" v-once>
              <v-card
                outlined
                class="transition-swing"
                :class="`elevation-${hover ? 5 : 0}`"
                :to="'/asset/' + asset.id"
              >
                <v-chip
                  v-if="!asset.active"
                  label
                  style="position: absolute; top: 5px; right: 5px; z-index: 1"
                  >Excluido</v-chip
                >
                <v-img
                  height="150"
                  position="left top"
                  :src="getAssetPreviewImageUrl(asset)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        :width="2"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <!-- <v-img height="150" :src="asset.preview_url"></v-img> -->

                <v-divider></v-divider>
                <v-card-text class="py-2 px-3 d-flex align-center">
                  <v-icon :color="colorType(asset.type)" class="mr-2 mb-auto">
                    {{ asset.type | iconType }}
                  </v-icon>
                  <p class="mb-0" style="word-break: break-all">
                    {{ asset.path }}
                  </p>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </template>
      <v-col cols="12" class="text-center" v-if="!allLoading && loading">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
        ></v-progress-circular>
      </v-col>
      <div
        v-if="!locked && assets.length"
        v-intersect="infiniteScrolling"
      ></div>
      <v-col cols="12" class="text-center grey--text" v-else-if="assets.length">
        Não há mais arquivos para carregar.
      </v-col>
      <!-- Sem arquivos -->
      <v-fade-transition hide-on-leave>
        <v-col v-if="!loading && !assets.length" class="text-center pt-16">
          <v-img
            width="200"
            src="@/assets/img/research.png"
            class="mx-auto"
          ></v-img>
          <h2 class="grey--text font-weight-medium">
            Nenhum arquivo encontrado!
          </h2>
        </v-col>
      </v-fade-transition>
    </v-row>
    <!-- Novo arquivo -->
    <v-btn fixed bottom right fab to="/asset/new" color="primary">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import { bus } from "../../../main";

export default {
  filters: {
    formatDate(date) {
      return date.split("-").reverse().join("/");
    },
    iconType(type) {
      switch (type) {
        case "EMAIL":
          return "mdi-email";
        case "JINJA":
          return "mdi-code-braces-box";
        case "PDF":
          return "mdi-file-pdf-box";
        case "IMAGE":
          return "mdi-image";
      }
    },
  },
  data: () => ({
    loading: true,
    locked: false,
    loadingPlus: false,
    page: 1,
    assets: [],
    filters: {},
  }),
  created() {
    bus.$emit("title", "");

    // Verifica se existe o parâmetro de pesquisa na URL
    let searchQuery = new URLSearchParams(window.location.search).get("search");

    if (searchQuery) {
      this.filters.content = searchQuery;
    }

    if (store.getters["home/getAssets"].length && !this.$route.params.refresh) {
      this.page = store.getters["home/getPage"];
      this.assets = store.getters["home/getAssets"];
      this.filters = store.getters["home/getFilters"];
      if (!Number.isInteger(this.assets.length / 30)) this.locked = true;
      setTimeout(() => (this.loading = false), 1000);
    } else {
      if (this.$route.params.refresh) {
        this.page = 1;
        this.ActionsReset();
      }

      if (this.$route.query.searchTerm) {
        this.filters.path = this.$route.query.searchTerm;
      }

      this.getAssets();
    }

    bus.$on("search", (filters) => {
      this.page = 1;
      this.locked = false;
      this.filters = filters;

      if (filters.type == "") delete filters.type;
      if (filters.content == "") delete filters.content;

      this.ActionsReset();
      this.ActionsSetFilters(filters);

      this.getAssets(true);
    });
  },
  destroyed() {
    bus.$off("search");
  },
  computed: {
    assetsPlusDate() {
      if (!this.assets.length) return [];
      let assets = {};
      let assetsPlusDate = [];
      this.assets.forEach((asset) => {
        if (typeof assets[asset.created_at.split("T")[0]] === "undefined") {
          assets[asset.created_at.split("T")[0]] = [];
        }
        assets[asset.created_at.split("T")[0]].push(asset);
      });
      Object.entries(assets).forEach((assetsByDate) => {
        assetsPlusDate.push({
          id: assetsByDate[0],
          date: assetsByDate[0],
          isDate: true,
          active: true,
        });
        assetsPlusDate = assetsPlusDate.concat(assetsByDate[1]);
      });
      return assetsPlusDate;
    },
    allLoading() {
      if (this.loading && !this.assets.length) return true;
      return false;
    },
  },
  methods: {
    ...mapActions("home", [
      "ActionsReset",
      "ActionsAssets",
      "ActionsSetPage",
      "ActionsSetFilters",
    ]),
    getAssets(newSearch = false) {
      this.loading = true;
      let payload = { ...this.filters };

      if (!payload.limit) payload.limit = 30;
      if (!payload.page) payload.page = this.page;
      if (!payload.active) payload.active = false;
      if (!payload.order) payload.order = "-created_at";

      this.ActionsAssets(payload)
        .then((newAssets) => {
          if (this.$route.query.searchTerm && newAssets.length == 1) {
            this.$router.push({
              path: "/asset/" + newAssets[0].id,
            });
          }

          if (newSearch) {
            this.assets = [];
            this.$vuetify.goTo(0, { duration: 0 });
          }
          if (newAssets.length) {
            this.assets = newAssets;
            this.page++;
            this.ActionsSetPage(this.page);

            if (newAssets.length < 30) {
              this.locked = true;
              this.loading = false;
            } else {
              this.locked = false;
            }
          } else {
            this.locked = true;
            this.loading = false;
          }
        })
        .finally(() => {
          bus.$emit("finshSearch");
          setTimeout(() => (this.loading = false), 1000);
        });
    },
    colorType(type) {
      switch (type) {
        case "EMAIL":
          return "blue darken-2";
        case "JINJA":
          return "blue-grey darken-2";
        case "PDF":
          return "red darken-2";
        case "IMAGE":
          return "green darken-2";
      }
    },
    infiniteScrolling() {
      if (this.loading) return;
      this.getAssets();
    },

    getAssetPreviewImageUrl(asset) {
      let paramsImage = {
        "crop-h": 800,
        width: 800,
      };

      let url =
        asset.preview_url +
        "?use_preview_params=true" +
        "&params=" +
        encodeURIComponent(JSON.stringify(paramsImage));

      if (asset.type == "PDF") {
        url = url.replace("/image?", "/image?input_format=pdf&");
      }

      return url;
    },
  },
};
</script>

<style scoped>
.qj-loading {
  width: 100%;
  height: calc(100vh - 168px);
}
</style>
